@import "../node_modules/primereact/resources/themes/lara-light-cyan/theme.css";

@media screen and (max-width: 400px) {
  #features {
    padding: 20px;
    width: 111%;
  }
  #about,
  #services,
  #testimonials,
  #team,
  #contact,
  #footer {
    width: 111%;
  }

  .bethema-bg {
    background: linear-gradient(to right, #000000 -20%, #ffc163 100%) !important;
  }

  #portfolio {
    width: 110%;
  }
}

.bethema-bg-color {
  background-image: linear-gradient(to right, #000000 -20%, #ffc163 100%);
}

.page-scroll {
  color: white !important;
}

.p-card {
  height: 500px;
  display: flex;
  flex-direction: column;
}

@media (max-width: 768px) {
  .p-card {
    height: 437px !important;
  }
  .d-mobile {
    display: contents !important;
  }

  .d-desktop {
    display: none !important;
  }

  #about img {
    margin-bottom: 0px !important;
  }
}

.d-desktop {
  display: contents;
}

.d-mobile {
  display: none;
}

.p-card-body {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.p-card-content {
  flex: 1;
}

.padding-top-70 {
  padding-top: 70px;
}

.padding-bottom-70 {
  padding-bottom: 70px;
}

.form-control {
  height: 45px !important;
}
